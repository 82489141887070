import React, { useState, useEffect } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const SpeechToText = () => {
    const [progress, setProgress] = useState(0);
    const [lyrics, setLyrics] = useState('');
    const [isConverting, setIsConverting] = useState(false);
    const [recognitionLanguage, setRecognitionLanguage] = useState("zh-CN");
    const [outputFormat, setOutputFormat] = useState("Detailed");
    const [enableDictation, setEnableDictation] = useState(true);
    const [profanityOption, setProfanityOption] = useState("Masked");
    const [totalBytes, setTotalBytes] = useState(0);
    const [processedBytes, setProcessedBytes] = useState(0);

    // 加载 Azure SDK
    useEffect(() => {
        loadAzureJs();
    }, []);

    const loadAzureJs = async () => {
        try {
            // 初始化基础配置
            const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
                "72d41ac760f340c6a5fe4f41b16a1dd6",
                "westus"
            );
            
            // 应用当前状态的配置
            updateSpeechConfig(speechConfig);
            
            // 存储基础配置
            window.speechConfig = speechConfig;
            
            console.log('Azure SDK 加载成功');
        } catch (error) {
            console.error('Azure SDK 加载失败:', error);
        }
    };

    // 更新配置的函数
    const updateSpeechConfig = (config) => {
        config.speechRecognitionLanguage = recognitionLanguage;
        config.outputFormat = SpeechSDK.OutputFormat[outputFormat];
        if (enableDictation) config.enableDictation();
        config.setProfanity(SpeechSDK.ProfanityOption[profanityOption]);
    };

    // 在配置改变时更新
    useEffect(() => {
        if (window.speechConfig) {
            updateSpeechConfig(window.speechConfig);
        }
    }, [recognitionLanguage, outputFormat, enableDictation, profanityOption]);

    const convertAudio = async (event) => {
        console.log('开始处理音频文件');
        const file = event.target.files[0];
        if (!file) {
            console.log('未选择文件');
            alert('请选择音频文件');
            return;
        }

        console.log('选择的文件:', file.name, '大小:', file.size, '类型:', file.type);

        setIsConverting(true);
        setProgress(0);
        setLyrics('');
        setTotalBytes(file.size);
        setProcessedBytes(0);

        try {
            // 使用已初始化的配置
            const speechConfig = window.speechConfig;
            if (!speechConfig) {
                console.error('Speech 配置未初始化');
                throw new Error('Speech 配置未初始化');
            }
            
            console.log('开始读取文件内容');
            const arrayBuffer = await file.arrayBuffer();
            console.log('文件内容读取完成, 大小:', arrayBuffer.byteLength);
            
            // 创建音频配置 - 支持 MP3
            console.log('创建音频配置');
            const audioConfig = SpeechSDK.AudioConfig.fromWavFileInput(
                new Uint8Array(arrayBuffer)
            );
            console.log("音频配置创建成功");
            
            console.log('创建识别器');
            const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
            console.log("识别器创建成功");

            // 处理识别过程
            recognizer.recognizing = (s, e) => {
                console.log("识别中...", e.result.text);
                // 更新已处理的字节数
                const newProcessedBytes = Math.min(processedBytes + (file.size / 10), file.size);
                setProcessedBytes(newProcessedBytes);
                // 计算进度百分比
                const currentProgress = Math.floor((newProcessedBytes / file.size) * 100);
                setProgress(Math.min(99, currentProgress));
            };

            // 处理识别结果
            recognizer.recognized = (s, e) => {
                console.log("识别完成一段:", e.result.text);
                if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                    displayLyrics(e.result.text);
                    setProcessedBytes(file.size);
                    setProgress(100);
                }
            };

            // 监听识别完成事件
            recognizer.sessionStopped = (s, e) => {
                console.log("识别会话结束");
                setIsConverting(false);
                setProgress(100);
                recognizer.stopContinuousRecognitionAsync();
            };

            // 监听错误事件
            recognizer.canceled = (s, e) => {
                console.log("识别被取消, 错误码:", e.errorCode);
                if (e.errorCode === SpeechSDK.CancellationErrorCode.NoError) {
                    setProgress(100);
                } else {
                    console.error('识别取消:', e.errorDetails);
                    alert(`识别取消: ${e.errorDetails}`);
                }
                setIsConverting(false);
            };

            // 开始识别
            console.log('开始连续识别');
            recognizer.startContinuousRecognitionAsync(
                () => console.log('连续识别开始成功'),
                (err) => console.error('连续识别开始失败:', err)
            );

        } catch (error) {
            console.error('转换失败:', error);
            alert(`转换失败: ${error.message}`);
            setIsConverting(false);
        }
    };

    const displayLyrics = (text) => {
        console.log('显示新识别的文本:', text);
        setLyrics(prevLyrics => prevLyrics + text + '\n');
        setIsConverting(false);
    };

    return (
        <div className="container mx-auto p-4 max-w-2xl">
            <h2 className="text-2xl font-bold mb-4">音频转歌词</h2>
            
            {/* 配置选项 */}
            <div className="card bg-base-200 p-4 mb-4">
                <div className="form-control gap-4">
                    <div className="flex items-center">
                        <label className="label">识别语言：</label>
                        <select 
                            className="select select-bordered w-full max-w-xs"
                            value={recognitionLanguage}
                            onChange={(e) => setRecognitionLanguage(e.target.value)}
                        >
                            <option value="zh-CN">中文</option>
                            <option value="en-US">英文</option>
                            <option value="ja-JP">日文</option>
                        </select>
                    </div>

                    <div className="flex items-center">
                        <label className="label">输出格式：</label>
                        <select 
                            className="select select-bordered w-full max-w-xs"
                            value={outputFormat}
                            onChange={(e) => setOutputFormat(e.target.value)}
                        >
                            <option value="Simple">简单</option>
                            <option value="Detailed">详细</option>
                        </select>
                    </div>

                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text">启用听写模式</span>
                            <input 
                                type="checkbox"
                                className="toggle"
                                checked={enableDictation}
                                onChange={(e) => setEnableDictation(e.target.checked)}
                            />
                        </label>
                    </div>

                    <div className="flex items-center">
                        <label className="label">敏感词处理：</label>
                        <select 
                            className="select select-bordered w-full max-w-xs"
                            value={profanityOption}
                            onChange={(e) => setProfanityOption(e.target.value)}
                        >
                            <option value="Masked">遮蔽</option>
                            <option value="Removed">移除</option>
                            <option value="Raw">保持原样</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* 文件输入 */}
            <div className="mb-4">
                <input 
                    type="file" 
                    accept="audio/wav,audio/mp3,audio/mpeg"  // 支持 WAV 和 MP3
                    onChange={convertAudio}
                    disabled={isConverting}
                    className="file-input file-input-bordered w-full"
                />
                <div className="text-sm text-gray-500 mt-1">
                    支持 WAV 和 MP3 格式的音频文件
                </div>
            </div>

            {/* 进度显示 */}
            {isConverting && (
                <div className="mb-4">
                    <progress 
                        className="progress progress-primary w-full" 
                        value={progress} 
                        max="100"
                    ></progress>
                    <div className="flex justify-between text-sm">
                        <span>{progress}%</span>
                        <span>{Math.floor(processedBytes/1024)}KB / {Math.floor(totalBytes/1024)}KB</span>
                    </div>
                </div>
            )}

            {/* 歌词显示区域 */}
            <div className="form-control">
                <textarea
                    value={lyrics}
                    readOnly
                    rows={10}
                    placeholder="转换后的歌词将显示在这里..."
                    className="textarea textarea-bordered h-64"
                />
            </div>
        </div>
    );
};

export default SpeechToText;
