import React, { useState } from 'react';

const BabyRecord = () => {
  const [records, setRecords] = useState([]);
  const [newRecord, setNewRecord] = useState({
    date: new Date().toISOString().split('T')[0], // 设置当前日期
    time: new Date().toLocaleTimeString('en-GB').slice(0,5), // 设置当前时间，格式为 HH:mm
    type: '喂奶',
    amount: '',
    note: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const record = { ...newRecord, id: Date.now() };
    setRecords([...records, record]);
    
    // 格式化为JSON并调用快捷指令
    const jsonData = JSON.stringify(record);
    const encodedData = encodeURIComponent(jsonData);
    window.open(`shortcuts://run-shortcut?name=babyrecord&input=${encodedData}`, "_blank");
    
    setNewRecord({
      date: new Date().toISOString().split('T')[0], // 重置为当前日期
      time: new Date().toLocaleTimeString('en-GB').slice(0,5), // 重置为当前时间
      type: '喂奶',
      amount: '',
      note: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewRecord(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">宝宝记录</h2>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="form-control">
              <label className="label">
                <span className="label-text">日期</span>
              </label>
              <input
                type="date"
                name="date"
                value={newRecord.date}
                onChange={handleChange}
                className="input input-bordered"
                required
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">时间</span>
              </label>
              <input
                type="time"
                name="time"
                value={newRecord.time}
                onChange={handleChange}
                className="input input-bordered"
                required
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">类型</span>
              </label>
              <select
                name="type"
                value={newRecord.type}
                onChange={handleChange}
                className="select select-bordered"
              >
                <option value="喂奶">喂奶</option>
                <option value="换尿布">换尿布</option>
                <option value="排便">排便</option>
                <option value="睡眠">睡眠</option>
                <option value="吐奶">吐奶</option>
                <option value="哭闹">哭闹</option>
                <option value="洗澡">洗澡</option>
                <option value="体温">体温</option>
                <option value="体重">体重</option>
                <option value="身高">身高</option>
                <option value="服药">服药</option>
                <option value="疫苗">疫苗</option>
                <option value="其他">其他</option>
              </select>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">数量 (ml)</span>
              </label>
              <input
                type="number"
                name="amount"
                value={newRecord.amount}
                onChange={handleChange}
                className="input input-bordered"
                placeholder="可选"
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">备注</span>
              </label>
              <textarea
                name="note"
                value={newRecord.note}
                onChange={handleChange}
                className="textarea textarea-bordered"
                placeholder="可选"
              />
            </div>

            <button type="submit" className="btn btn-primary">
              添加记录
            </button>
          </form>

          <div className="divider"></div>

          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>日期</th>
                  <th>时间</th>
                  <th>类型</th>
                  <th>数量</th>
                  <th>备注</th>
                </tr>
              </thead>
              <tbody>
                {records.map(record => (
                  <tr key={record.id}>
                    <td>{record.date}</td>
                    <td>{record.time}</td>
                    <td>{record.type}</td>
                    <td>{record.amount}</td>
                    <td>{record.note}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BabyRecord;
