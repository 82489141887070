import React from 'react';

const IOSShortCut = () => {
  const openShortcut = () => {
    window.open("shortcuts://run-shortcut?name=导航&input=你的输入", "_blank");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">iOS 快捷指令</h2>
          <div className="mt-4">
            <button 
              onClick={openShortcut}
              className="btn btn-primary"
            >
              打开快捷指令
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IOSShortCut;